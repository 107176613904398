@import '_utility';

.impressum {
    font-size: 20px;

    span {
        font-weight: bold;
        color: $blu;
    }

    @include media('>tablet') {
        font-size: 28px;
    }
}

.plus--wrap {
    columns: 2;
}

.plus--item {
    text-transform: uppercase;
    color: $blu;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 13px;

    span {
        font-size: 18px;
        font-weight: 400;
        margin-right: 10px;
        display: inline-block;
    }
}

.splash--video {
    background: url('/assets/img/ftf-video-fallback.jpg') no-repeat center center;
    background-size: 100% auto;
}
